import React from 'react'

// import urlimage from 


const contectrightpart = () => {
  return (
    <div id="contactRPcontainer"> 
      <img id="contactRPimage" src="Images/contact.jpg" alt="house"/>
    
    </div>
  )
}

export default contectrightpart

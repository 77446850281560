import React from 'react'
// import myimage from 



const Rightpart = () => {
  return (
    <div id="rpcontainer">
      <img id="rpimage"src="../Images/hero-image.png" />
    </div>
  )
}

export default Rightpart

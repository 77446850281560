import React from 'react'
// import image from  

const Header = () => {
  return (
    <>
      
<div id="container">

<div ><img id="logo" src="../Images/logo.png" /></div>
<div id="navbar">

<div className="font-larger" id="residencies">Residencies</div>
<div className="font-larger" id="Our Value">Our Value</div>
<div className="font-larger" id="Contact Us">Contact Us</div>
<div className="font-larger" id="Get Started">Get Started</div>
<button id="contact">Contact</button>

</div>



</div>
 
    </>
  )
}

export default Header

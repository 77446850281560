import React from 'react'
import LeftPartFAQ from './LeftPartFAQ'
import RightPartFAQ from './RightPartFAQ'

const Values = () => {
  return (
    <>
  <LeftPartFAQ/>
  <RightPartFAQ/>
      
    </>
  )
}

export default Values

import React from 'react'

const LeftPartFAQ = () => {
  return (
    <div id="LPFAQcontainer">
      <img id="LPFAQimage" src="Images/value.png"/>
    </div>
  )
}

export default LeftPartFAQ

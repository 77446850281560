import React from 'react'
import FAQ from "./FAQ"

const RightPartFAQ = () => {
  return (
    <div id="RPFAQcontainer">

<div id="RPFAQtitle1">Our Value</div>
<div id="RPFAQtitle2">Value We Give to You</div>
<div id="RPFAQdesc">We always ready to help by providijng the best services for you.
We beleive a good blace to live can make your life better
Best interest rates on the market
</div>
        <FAQ/>
    </div>
  )
}

export default RightPartFAQ

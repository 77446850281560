import React from 'react'

import Slider from "./Slider.jsx"




const Residencies = () => {
  return (
    <div id="rcontainer">
      
<div id="rbest">Best Choices</div>
<div id="rpopular">Popular Residencies</div>
<Slider/>




    </div>
  )
}

export default Residencies

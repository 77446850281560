import React from 'react'

const FooterRightPart = () => {
  return (
    <div id="FooterRPcontainer">
      <div id="FooterRPtitle">Information</div>
      <div id="FooterRPlocation">145 New York, FL 5467, USA
</div>

<div id="FooterRPflexcontainer">
    <div className="boldd"id="FooterRPfirst">Property</div>
    <div className="boldd" id="FooterRPsecond">Services</div>
    <div className="boldd" id="FooterRPthird">Product</div>
    <div className="boldd" id="FooterRPfourth">About Us</div>
</div>


    </div>
  )
}

export default FooterRightPart

import React from 'react'
import FooterLeftPart from './FooterLeftPart'
import FooterRightPart from './FooterRightPart'




const Footer = () => {
  return (
    <>
      <FooterLeftPart/>
      <FooterRightPart/>
    </>
  )
}

export default Footer

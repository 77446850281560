import React from 'react'

// import value from "/Images/prologis.png"


const Brand = () => {
  return (
    <div id="bcontainer"> 
    <img className="bimage" src="Images/prologis.png"/>
    <img className="bimage" src="Images/equinix.png"/>
      <img className="bimage"src="Images/tower.png"/>
      <img className="bimage" src="Images/realty.png"/>
      
     
    </div>
  )
}

export default Brand

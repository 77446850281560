import React from 'react'
import LeftContact from "./contactleftpart"
import RightContact from "./contectrightpart"




const Contact = () => {
  return (
    <div id="contactcontainer">
      <LeftContact/>
<RightContact/>




    </div>
  )
}

export default Contact

import React from 'react'

const Subscribe = () => {
  return (
    <div id="subscribecontainer">
      <div id="subscribetitle">Get started with Homyz</div>
    <div id="subscribedesc">Subscribe and find super attractive price quotes from us.
Find your residence soon</div> 
    <div id="subscribebut">Get Started</div>
    
    </div>
  )
}

export default Subscribe

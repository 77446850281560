// import '..Components/';
import Header from "./Header"
import FrontPage from  "./Front_Page"
import Brand from "./Brand"
import Residencies from "./Residencies"
import Values from "./Values"
import Contact from "./Contact"
import Subscribe from "./Subscribe"
import Footer from "./Footer"



function App() {
  return (
    
    <>
  
    <Header/>
<FrontPage/>

<Brand/>
<Residencies/>
<Values/>
<Contact/>
<Subscribe/>
<Footer/>




    </>
    
    
    );
}

export default App;
